@import '~antd/dist/antd.css';
@import '~react-simple-keyboard/build/css/index.css';

* {
  --accentColor: #00a356;
  /* --accentColor: #0023ff; */
  --primaryBgColor: #f4f4f4;
  --dividerColor: #dbdbdb;
  --orangeColor: #ac7a18;
}

body {
  background-color: var(--primaryBgColor);
}

.App {
  height: 100vh;
}

.App > section {
  /*height: 100%;*/
}

.ant-avatar {
  background: url("../assets/images/golf-avatar.svg") no-repeat center center !important;
  background-size: cover !important;
  color: transparent !important;
  height: 36px !important;
  width: 36px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--accentColor) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/***layout css***/
.site-layout-content {
  min-height: 280px;
  padding: 10px;
  background: var(--primaryBgColor);
}

.ant-layout, .ant-layout-footer {
  background: var(--primaryBgColor) !important;
}

.ant-menu {
  box-shadow: 0 0 8px rgba(0, 0, 0, .1);
  /*position: relative;
  display: flex;*/
  overflow: hidden;
}

.ant-layout-header, .ant-menu {
  background: white !important;
}

.logo {
  float: left;
  width: 36px;
  height: 36px;
  background: url("../assets/images/lockers-logo-icon.svg") no-repeat left center;
  background-size: cover;
  margin-left: 1rem;
}
.ant-row-rtl .logo {
  
  margin: 16px 0 16px 24px;
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  /*padding-top: 80px;*/
  text-align: center;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.perfil {
  
}

.ant-layout-content {
  padding: 0 50px;
  /*margin-top: 2rem;*/
}

.ant-steps-item-title-block{
  display: none;
}

.ant-layout-header, .ant-menu-horizontal {
  height: 50px !important;
}

@media only screen and (max-width: 767px) {
  /* For mobile phones: */
  .ant-layout-header {
    padding: 0 !important;
  }

  .ant-steps {
    flex-direction: row !important;
  }

  .home h1 {
    font-size: 1.2rem !important;
  }

  .ant-layout-content {
    padding: 0;
  }
  .logo {
    float: left;
    width: 36px;
    height: 36px;
  }
  .ant-steps-item-title{
    font-size: 14.5px !important;
  }
  .ant-steps-item-title-block{
    display: none!important;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 0 10px !important;
  }

  .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
    right: 10px !important;
    left: 10px !important;
  }
/*  .ant-layout-header .ant-menu {
    width: auto !important;
    overflow: hidden;
  }*/
  .menuPrincipal{
    width: 50px !important;
  }
}

@media only screen and (max-width: 327px) {
  .ant-steps {
    flex-direction: column!important;
  }
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  margin-right: 10px !important;
}

.div_scan{
  max-height: 400px;
  max-width: 400px;
}


.hiddeclass{
  display: none;
}

video {
  width: 100%!important;
  height: 100%!important;
}

.lista_elem{
  max-height: 300px;
  overflow-x: auto;
}

.home .ant-radio-group {
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
  border-bottom: 1px solid var(--dividerColor) !important;
}

.ant-radio-button-wrapper {
  background: transparent !important;
  border-radius: 0 !important;
  border: 2px solid var(--dividerColor);
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 0 1rem;
  font-size: .9rem;
  font-weight: 400;
  position: relative;
  top: 1px;
}

.ant-radio-button-wrapper-checked {
  color: var(--accentColor) !important;
  border-bottom: 2px solid var(--accentColor) !important;
}

.ant-radio-button-wrapper:hover {
  color: var(--accentColor) !important;
}

.ant-radio-button-wrapper:not(:first-child):before {
  padding: 0 !important;
  background: transparent !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none !important;
}

.ant-image {
  display: flex !important;
  justify-content: center !important;
  width: 100%;
}

.ant-image img {
  /* width: calc(100vw / 2.5); */
}

.buscar-usuario > div {
  display: flex;
  justify-content: center;
}

.buscar-usuario > div img {
  width: 50%;
  padding: .5rem;
}

.home h1 {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 400;
  /* width: calc(100vw/2); */
  line-height: 1.3;
  color: #6d6d6d;
}

.home > div:nth-child(3) > div:nth-child(2), .userslist {
  background: white;
  padding: 2rem;
  box-shadow: 0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3);
}

.users > div:nth-child(3) > div:first-child, .users > div:nth-child(3) > div:nth-child(2) {
  
}

/* .home h1:before {
  content: "";
  width: 55px;
  height: 36px;
  background: url("../assets/images/golf-icon-001.svg") no-repeat left center;
  background-size: 36px 36px;
  position: absolute;
  z-index: 2;
  left: 10px;
  display: inline-block;
} */

.ant-steps-item-wait .ant-steps-item-icon {
  background: transparent !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: var(--accentColor) !important;
  border-color: var(--accentColor) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--accentColor) !important;
  color: var(--accentColor) !important;
  background: transparent !important;
}

.ant-steps-item-finish .ant-steps-item-icon svg {
  fill: var(--accentColor) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--accentColor) !important;
}

.home .ant-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--dividerColor);
}

.home > div:first-child {
  /* height: 74px; */
}

.home .ant-input-search .ant-btn, .home .ant-input-group-addon{
  background: transparent;
  border: none !important;
}

.steps-content > div:first-child {
  margin-bottom: 1rem;
}

.ant-btn-primary {
  outline: none;
  border-color: var(--accentColor) !important;
  background-color: transparent !important;
  color: var(--accentColor) !important;
  -webkit-transition: all .5s;
  transition: all .5s;
  border-radius: 500px !important;
  padding: 18px 36px 18px 36px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-btn {
  outline: none;
  background-color: transparent;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.ant-btn-icon-only.ant-btn-lg {
   width: 38px;
   height: 38px;
 }

.steps-action {
  display: flex;
  justify-content: center;
  margin: 0;
}

.home .ant-select {
  width: 100%;
}

.ant-card-bordered {
  border: none !important;
}

/* main menu */

.ant-menu-overflow {
  align-items: center;
}

.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
  bottom: 8px !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a, .ant-menu-horizontal > .ant-menu-item a:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: var(--accentColor) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-input-search .ant-input:hover {
  border-bottom-color: var(--accentColor) !important;
}

.ant-input:focus, .ant-input-focused {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-menu-horizontal {
  border: none !important; 
}

.ant-layout-header {
  box-shadow: 0 1px 8px rgba(0,0,0,.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* warning message */

.warning {
  background: #ffefd6;
  padding: 0.6rem;
  border-left: 2px solid var(--orangeColor);
  color: var(--orangeColor);
  font-weight: 500;
}

.warning span {
  margin-right: .5rem;
}

.warning span svg {
  fill: var(--orangeColor);
}

/* lista_elem */

.lista_elem {
  /*display: flex;*/
  margin-top: 1rem;
}

.lista_elem > div {
  width: 100%;
}

.lista_elem .ant-card-body {
  padding: 14px !important;

}

.lista_elem .ant-card-body .ant-row {
  background: rgba(0,0,0,.02);
}

.userslist {
  margin-top: 1rem;
}

/* user submenu */

.ant-menu-sub {
  display: flex;
  flex-direction: column;
  
}

.ant-menu-submenu {
  box-shadow: 0 13px 27px -5px rgba(50,50,93,0.25),0 8px 16px -8px rgba(0,0,0,0.3) !important;
  max-width: fit-content;
}

.ant-spin-container > div > div > div > div {
  box-shadow: 0 6px 12px -2px rgba(50,50,93,0.25),0 3px 7px -3px rgba(0,0,0,0.3);
}

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.lockers-stats {
  background: rgba(0,0,0,.03);
  padding: 1rem !important;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.03) 100%);
}

.users > div:nth-child(2) > p:first-child, .lockers > div:nth-child(2) > p:first-child {
  text-align: center;
}

.slick-dots > li{
  background-color: #dbdbdb;
  width: 20px!important;
  height: 16px!important;
}


.slick-dots .slick-active button{
  background-color: #00a356!important;
}


.slick-dots li button {
  width: 100%!important;
  height: 100%!important;
  color: #dbdbdb;
}

.ant-carousel .slick-dots {
  margin-top: -16px;
}

.ant-list .ant-spin-nested-loading .ant-spin-container .ant-list-items .ant-list-item .ant-list-item-action {
  width: 100%!important;
}

.ant-list .ant-spin-nested-loading .ant-spin-container .ant-list-items .ant-list-item .ant-list-item-action li{
  width: 100%!important;
}


/***Loading***/
.loader {
  margin: auto;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #00a356; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  top: 35%;
  position: relative;
}
#container_loader {
  width: 100%; /* Can be in percentage also. */
  height: 100%;
  margin: 0 auto;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.8;
  z-index:1000;
}
/* Style buttons */
.btn {
  background-color: #343a40;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
/* Darker background on mouse-over */
.btn:hover {
  background-color: RoyalBlue;
}
.center {
  margin: auto;
  width: 50%;
  border: 3px solid gray;
  padding: 50px 0;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.submenuAdmin {
  max-width: 100%!important;
  box-shadow: 0 13px 7px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%) !important;
}

.ant-input {
  font-size: 16px!important;
}

.site-layout-content{
  margin-top: 2rem;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: var(--accentColor);
}

.hg-theme-default {
  background-color: var(--primaryBgColor);
  color: rgba(0, 0, 0, 0.85);
}
